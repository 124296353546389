.section-pages-link-module::after only-child {
    float: none;
}

.section-pages-link-module .description {
    text-align: center;
    font-size: var(--font-size-body1);
    font-weight: 700;
    margin-bottom: 30px;
    line-height: 1.43;
}

/* BP 3+ */
@media only screen and (min-width: 768px) {
    .section-pages-link-module {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}